
  import { Component, Prop, Vue } from "vue-property-decorator";

  @Component
  export default class PropertyDesktopWrapper extends Vue {
    @Prop({ required: true }) label!: string;
    @Prop({ required: false }) labelClass!: string;
    @Prop({ required: false, default: false }) required!: boolean;
    @Prop({ default: 2 }) cols!: 1 | 2;
    @Prop({ default: 0 }) gap!: 0 | 8 | 32;
  }
